import { useCallback, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useSub } from '@core/event-bus';
import { useAuth } from '@modules/auth';

import { useHandoverQueueList, useRemoveHandoverQueue, useRemoveHandoverQueueItem, useUpdateHandoverQueueStatus } from '../hooks';
import { queryClient } from '../../../index';
import { defaultFilterEnabled } from '../utils';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

export const useHandoverQueues = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { handoverType } = useParams<{ handoverType: string }>();
  const [checkPrint, setCheckPrint] = useState<boolean>(defaultFilterEnabled());

  const onChangeFilter = useCallback((e: CheckboxChangeEvent) => {
    setCheckPrint(e.target.checked);
    localStorage.setItem('no_filter_warehouse_check', e.target.checked.toString());
  }, []);

  const { data, isLoading, error } = useHandoverQueueList(
    { state_id: [41], branch_id: user.data?.adminBranchId || undefined },
    {
      onSuccess: (data) => {
        if (data.activeTasks.length) {
          history.push(generatePath('/@next/warehouse/handover/queues/:queueId', { queueId: data.activeTasks[0] }));
        }
      },
    },
  );

  const updateStatus = useUpdateHandoverQueueStatus();
  const remove = useRemoveHandoverQueue();
  const removeItem = useRemoveHandoverQueueItem();

  const execute = useCallback(
    (id: number) => {
      updateStatus.mutate(
        { queueId: id, statusId: 42 },
        {
          onSuccess: (data, variables) => {
            history.push(generatePath('/@next/warehouse/handover/queues/:queueId', { queueId: variables.queueId }));
          },
        },
      );
    },
    [history, updateStatus],
  );

  useSub('@counter/handoverQueue/changed', async () => {
    await queryClient.invalidateQueries(['warehouse', 'handover-items']);
  });

  return { handoverType, data, isLoading, error, updateStatus, remove, execute, removeItem, checkPrint, onChangeFilter };
};
