import { useCallback } from 'react';
import { message } from 'antd';
import * as handlebars from 'handlebars';

import { handoverTemplate } from '../templates';
import { IAuthUser } from '@modules/auth';
import moment from 'moment';
import { Constants } from '@core/constants';

export const usePrintDeclarationHandoverCheck = (user: IAuthUser | null) => {
  return useCallback(
    async (data: any) => {
      if (!data || !Object.keys(data).length) return;
      message.loading('Çek hazırlanır.');

      handlebars.registerHelper('addOne', function (value) {
        return parseInt(value) + 1;
      });

      try {
        const template = await fetch(handoverTemplate).then((r) => r.text());
        message.destroy();

        const renderedTemplate = handlebars.compile(template)({
          warehouseman: `${user?.firstName} ${user?.lastName}`,
          checkDate: moment().format(Constants.DATE_TIME),
          ...data,
        });

        const printWindow = window.open();

        if (printWindow) {
          printWindow.document.write(renderedTemplate);
          printWindow.document.close();
        } else {
          message.error('Brauzerdə xəta baş verdi.');
        }
      } catch (e) {
        message.error('Şablon əldə edilə bilmədi.');
      }
    },
    [user],
  );
};
