import groupBy from 'lodash/groupBy';

import { IDetailedHandoverQueue, IDetailedHandoverQueueDeclaration, IDetailedHandoverQueuePersistence } from '../interfaces';

export class DetailedHandoverQueueMapper {
  public static toDomain(item: IDetailedHandoverQueuePersistence): IDetailedHandoverQueue {
    const mappedItem: Omit<IDetailedHandoverQueue, 'boxes' | 'declarations'> = {
      id: item.id,
      user: {
        id: item.user_id,
        fullName: item.user_name,
      },
      cashier: {
        id: item.cashier_id,
        fullName: item.cashier_name,
      },
      status: {
        id: item.state_id,
        name: item.state_name,
      },
      packages: {
        bigPackage: item.packages?.big_package || 0,
        mediumPackage: item.packages?.medium_package || 0,
        smallPackage: item.packages?.small_package || 0,
      },
      createdAt: item.created_at,
    };

    const mappedDeclarations: IDetailedHandoverQueueDeclaration[] = item.declarations.map((declaration) => ({
      id: declaration.declaration_id,
      trackCode: declaration.track_code,
      wardrobeNumber: declaration.wardrobe_number,
      trendyol: declaration.trendyol,
      box:
        declaration.container_id && declaration.container_name
          ? {
              id: declaration.container_id,
              name: declaration.container_name,
            }
          : null,
      productType:
        declaration.product_type_id && declaration.product_type_name
          ? {
              id: declaration.product_type_id,
              name: declaration.product_type_name,
            }
          : null,
      shop: declaration.shop_name,
      weight: declaration.weight ? parseFloat(declaration.weight) : null,
    }));

    const groupedItems = groupBy(mappedDeclarations, (declaration) => declaration.box?.id || 0);
    const boxes = Object.entries(groupedItems).map(([boxId, items]) => ({
      id: parseInt(boxId),
      name: mappedDeclarations.find((declaration) => declaration.box?.id === parseInt(boxId))?.box?.name || 'Digər',
      items,
    }));

    return {
      ...mappedItem,
      declarations: mappedDeclarations,
      boxes,
    };
  }
}
