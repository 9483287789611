import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { ActionBar, GhostMenu, PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { HeadPortal } from '@modules/layout';

import { HandoverQueueTableContext } from '../context';
import { useHandoverQueueTableContext } from '../hooks';
import { HandoverQueueTable } from '../containers';

export const HandoverHistoryPage = () => {
  const { handoverType } = useParams<{ handoverType: string }>();
  const { handleFetch } = useHandoverQueueTableContext();

  return (
    <TableProvider name='handover-queue' context={HandoverQueueTableContext} onFetch={handleFetch}>
      <PageContent $contain={true}>
        <HeadPortal>
          <ActionBar>
            <GhostMenu selectedKeys={[handoverType]} mode='horizontal'>
              <GhostMenu.Item key='queue'>
                Təhvil
                <NavLink to='/@next/warehouse/handover/queues' />
              </GhostMenu.Item>
              <GhostMenu.Item key='history'>
                Tarixçə
                <NavLink to='/@next/warehouse/handover/history' />
              </GhostMenu.Item>
            </GhostMenu>
          </ActionBar>
        </HeadPortal>
        <HandoverQueueTable />
      </PageContent>
    </TableProvider>
  );
};
