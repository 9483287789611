import { useMutation } from 'react-query';
import { useService } from '@core/inversify-react';

import { IHandoverQueue } from '../interfaces';
import { queryClient } from '../../../index';
import { IRemoveHandoverQueueItemRepo, RemoveHandoverQueueItemRepoType } from '../repos';

export const useRemoveHandoverQueueItem = () => {
  const removeHandoverQueueItemRepo = useService<IRemoveHandoverQueueItemRepo>(RemoveHandoverQueueItemRepoType);

  return useMutation<any, Error, { queueId: number; declarationId: number }>(
    async ({ queueId, declarationId }) => {
      const result = await removeHandoverQueueItemRepo.execute({ queueId, declarationId });

      if (result.status !== 200) {
        throw new Error(result.response);
      }
    },
    {
      onMutate: async (newItem) => {
        await queryClient.cancelQueries(['warehouse', 'handover-items']);

        const previousHandoverItems = queryClient.getQueryData(['warehouse', 'handover-items']);

        queryClient.setQueryData<IHandoverQueue[]>(['warehouse', 'handover-items'], (queues) => {
          return (
            queues?.map((queue) => (queue.id === newItem.queueId ? { ...queue, declarations: queue.declarations.filter((declaration) => declaration.id !== newItem.declarationId) } : queue)) || []
          );
        });

        return { previousHandoverItems };
      },
      onError: (error, newItem, context: any) => {
        queryClient.setQueryData(['warehouse', 'handover-items'], context.previousHandoverItems);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(['warehouse', 'handover-items']);
      },
    },
  );
};
