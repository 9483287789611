import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, Card, Col, Modal, Row, Space, Statistic, Typography } from 'antd';
import { useQuery } from 'react-query';
import { CheckboxField, TextField } from '@core/form';
import { AntForm, SimpleTable } from '@core/ui';

import { IHandoverDeclarationDto } from '../dto';
import { useHandoverDeclarations } from '../hooks';
import { HandoverDeclarationsContext } from '../context';
import { IHandoverDeclarationsDetails } from '../interfaces';
import { GetHandoverDeclarationsDetailsRepoType, IGetHandoverDeclarationsDetailsRepo } from '../repos';
import { useService } from '@core/inversify-react';

const FormikComponent: FC<FormikProps<IHandoverDeclarationDto>> = ({ handleSubmit, isSubmitting, values, setFieldValue }) => {
  const { ids, onClose } = useContext(HandoverDeclarationsContext);
  const [sumSyncAmount, setSumSyncAmount] = useState<number>(parseFloat(values.cashAmount));

  const getHandoverDeclarationDetailsRepo = useService<IGetHandoverDeclarationsDetailsRepo>(GetHandoverDeclarationsDetailsRepoType);

  const details = useQuery<IHandoverDeclarationsDetails, Error, IHandoverDeclarationsDetails, [string, string[], string, string, string, string, string]>(
    ['declarationshandover', ids, 'handover', 'details', values.medium_package, values.small_package, values.big_package],
    async ({ queryKey }) => {
      const [, ids] = queryKey;
      const result = await getHandoverDeclarationDetailsRepo.execute({ ids, body: { medium_package: values.medium_package, small_package: values.small_package, big_package: values.big_package } });
      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
  );

  const onIncrement = useCallback(
    (field: string) => {
      let packagePrice = parseInt(values[field]) || 0;
      if (packagePrice < 20) packagePrice++;
      setFieldValue(field, packagePrice.toString());
    },
    [values, setFieldValue],
  );

  const onDecrement = useCallback(
    (field: string) => {
      let packagePrice = parseInt(values[field]) || 0;
      if (packagePrice > 0) packagePrice--;
      setFieldValue(field, packagePrice.toString());
    },
    [values, setFieldValue],
  );

  useEffect(() => {
    const terminalAmount = parseFloat(values.terminalAmount) || 0;
    const cashAmount = Math.round((sumSyncAmount - terminalAmount) * 100) / 100;

    if (cashAmount < 0) {
      setFieldValue('cashAmount', '0');
    } else {
      setFieldValue('cashAmount', cashAmount.toString());
    }

    if (terminalAmount > sumSyncAmount) {
      setFieldValue('terminalAmount', sumSyncAmount.toString());
    } else if (terminalAmount < 0) {
      setFieldValue('terminalAmount', '');
    }
  }, [setFieldValue, sumSyncAmount, values.terminalAmount]);

  useEffect(() => {
    if (values.handoverPreparations) {
      setFieldValue('handover', false);
    }
  }, [setFieldValue, values.handoverPreparations]);

  useEffect(() => {
    if (details.data) {
      setSumSyncAmount(details.data.debt.all.azn);
    }
  }, [details.data]);

  const amountToBeGivenBack = useMemo(() => {
    if (details.data && details.data.debt.all.azn && values.amountToBeBorrowed.length) {
      let borrowAmount = parseFloat(values.amountToBeBorrowed);
      if (isNaN(borrowAmount)) borrowAmount = 0;
      const amount = borrowAmount - details.data.debt.all.azn;
      if (amount > 0) return { color: 'green', amount };
      if (amount < 0) return { color: 'red', amount };
      return { color: 'black', amount };
    } else {
      return { color: 'black', amount: 0 };
    }
  }, [details, values.amountToBeBorrowed]);

  const title = useMemo(() => {
    return (
      <Space size={12}>
        <Typography.Title level={5}>Bağlamaları təhvil ver</Typography.Title>
        {values.debt.total.usd > 0 && (
          <Typography.Title level={5} style={{ color: 'red' }}>
            Ödəniş tələb edilir
          </Typography.Title>
        )}
      </Space>
    );
  }, [values]);

  if (!details) {
    return null;
  }

  return (
    <Modal visible={true} width={576} title={title} onOk={() => handleSubmit()} confirmLoading={isSubmitting} onCancel={onClose}>
      <Row gutter={[0, 24]}>
        <Col xs={24}>
          <SimpleTable>
            <tbody>
              <tr>
                <th>Sifariş</th>
                <td>{details.data?.ordersPrice.try.toFixed(2) || values?.ordersPrice.try.toFixed(2)} ₺</td>
                <td>{details.data?.ordersPrice.azn.toFixed(2) || values?.ordersPrice.azn.toFixed(2)} ₼</td>
              </tr>
              <tr>
                <th>Bağlama</th>
                <td>{details.data?.deliveryPrice.usd.toFixed(2) || values?.deliveryPrice.usd.toFixed(2)} $</td>
                <td>{details.data?.deliveryPrice.azn.toFixed(2) || values?.deliveryPrice.azn.toFixed(2)} ₼</td>
              </tr>
              <tr>
                <th>Borc</th>
                <td>{details.data?.debt.total.usd.toFixed(2) || values?.debt.total.usd.toFixed(2)} $</td>
                <td>{details.data?.debt.total.azn.toFixed(2) || values?.debt.total.azn.toFixed(2)} ₼</td>
              </tr>
              <tr>
                <th>Paket qiyməti</th>
                <td></td>
                <td>{details.data?.debt.all.package || values?.debt.all.package} ₼</td>
              </tr>
              <tr>
                <th colSpan={2}>Cəmi ödəniləcək</th>
                <td>
                  <Button onClick={() => setSumSyncAmount(details.data?.debt.all.azn! || values?.debt.all.azn)} style={{ padding: 0 }} size='small' type='link'>
                    {details.data?.debt.all.azn.toFixed(2) || values?.debt.all.azn.toFixed(2)} ₼
                  </Button>
                </td>
              </tr>
              <tr>
                <th colSpan={2}>Minimal ödəniləcək </th>
                <td>
                  <Button onClick={() => setSumSyncAmount(details.data?.debt.minimum.azn! || values?.debt.minimum.azn!)} style={{ padding: 0 }} size='small' type='link'>
                    {details.data?.debt.minimum.azn.toFixed(2) || values?.debt.minimum.azn.toFixed(2)} ₼
                  </Button>
                </td>
              </tr>
            </tbody>
          </SimpleTable>
        </Col>
        <Col xs={24}>
          <Card title='Balans' size='small' bodyStyle={{ background: '#fafafa' }}>
            <Space style={{ flex: 1, justifyContent: 'center' }} size={8}>
              <div style={{ backgroundColor: 'white', border: '0.5px solid #ecf0f1', padding: '5px 10px' }}>
                <Statistic title='ABŞ Dolları' value={details.data?.balance.usd || values?.balance.usd} precision={2} prefix='&#36;' />
              </div>
              <div style={{ backgroundColor: 'white', border: '0.5px solid #ecf0f1', padding: '5px 10px' }}>
                <Statistic title='Türk Lirəsi' value={details.data?.balance.try || values?.balance.try} precision={2} prefix='&#8378;' />
              </div>
            </Space>
          </Card>
        </Col>
        <Col xs={24}>
          <AntForm layout='vertical'>
            <Row gutter={[24, 24]}>
              <Col lg={6}>
                <TextField name='cashAmount' format='decimal' item={{ label: 'Nağd', style: { flex: 1 } }} input={{ placeholder: 'Nağd', suffix: '₼', disabled: true }} />
              </Col>
              <Col lg={6} style={{ borderRight: '1px solid black' }}>
                <TextField
                  name='terminalAmount'
                  format='decimal'
                  item={{ label: 'Terminal', style: { flex: 1 } }}
                  input={{ placeholder: 'Terminal', suffix: '₼', disabled: values.sync === 'terminal' }}
                />
              </Col>
              <Col lg={6}>
                <TextField name='amountToBeBorrowed' format='decimal' item={{ label: 'Alınan məbləğ', style: { flex: 1 } }} input={{ placeholder: 'Alınan məbləğ', suffix: '₼' }} />
              </Col>
              <Col lg={6}>
                Qaytarılmalı məbləğ: <span style={{ color: amountToBeGivenBack.color }}>{amountToBeGivenBack.amount.toFixed(2)} AZN</span>
              </Col>
            </Row>
            <Space style={{ marginTop: 20 }} size={12}>
              <Row gutter={[24, 24]}>
                <Col xl={24}>
                  <CheckboxField name='handover' input={{ children: 'Təhvil ver', style: { marginBottom: '0' } }} />
                </Col>
                <Col xl={24}>
                  <CheckboxField name='handoverPreparations' input={{ children: 'Anbara yönəlt' }} />
                </Col>
                <Col xl={24}>
                  <CheckboxField name='redirectToBalance' input={{ children: 'Balans artır' }} />
                </Col>
              </Row>
              <Row style={{ marginTop: 20, marginLeft: 100 }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                  <span style={{ margin: '0 5px', marginBottom: 24 }}>Böyük:</span>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button style={{ marginBottom: 24 }} onClick={() => onDecrement('big_package')}>
                      -
                    </Button>
                    <TextField name='big_package' format='decimal' item={{ style: { width: '100px' } }} input={{ placeholder: ' Böyük paket...', max: 20, min: 0 }} />
                    <Button style={{ marginBottom: 24 }} onClick={() => onIncrement('big_package')}>
                      +
                    </Button>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                  <span style={{ margin: '0 5px', marginBottom: 24 }}>Orta:</span>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button style={{ marginBottom: 24 }} onClick={() => onDecrement('medium_package')}>
                      -
                    </Button>
                    <TextField name='medium_package' format='decimal' item={{ style: { width: '100px' } }} input={{ placeholder: 'Orta paket...', max: 20, min: 0 }} />
                    <Button style={{ marginBottom: 24 }} onClick={() => onIncrement('medium_package')}>
                      +
                    </Button>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                  <span style={{ margin: '0 5px', marginBottom: 24 }}>Kiçik:</span>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button style={{ marginBottom: 24 }} onClick={() => onDecrement('small_package')}>
                      -
                    </Button>
                    <TextField name='small_package' format='decimal' item={{ style: { width: '100px' } }} input={{ placeholder: 'Kiçik paket...', max: 20, min: 0 }} />
                    <Button style={{ marginBottom: 24 }} onClick={() => onIncrement('small_package')}>
                      +
                    </Button>
                  </div>
                </div>
              </Row>
            </Space>
          </AntForm>
        </Col>
      </Row>
    </Modal>
  );
};

export const HandoverDeclarations: FC = () => {
  const { initialValues, onSubmit } = useHandoverDeclarations();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit} component={FormikComponent} />;
};
