import { useMutation } from 'react-query';
import { useService } from '@core/inversify-react';

import { queryClient } from '../../../index';
import { IRemoveHandoverQueuesRepo, RemoveHandoverQueuesRepoType } from '../repos';

export const useRemoveHandoverQueue = () => {
  const removeHandoverQueueRepo = useService<IRemoveHandoverQueuesRepo>(RemoveHandoverQueuesRepoType);

  return useMutation<any, Error, { queueId: number }>(
    async ({ queueId }) => {
      const result = await removeHandoverQueueRepo.execute({ queueIds: [queueId] });

      if (result.status !== 200) {
        throw new Error(result.response);
      }
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(['warehouse', 'handover-items']);
      },
    },
  );
};
