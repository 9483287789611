import { IHandoverQueue, IHandoverQueuePersistence } from '../interfaces';

export class HandoverQueueMapper {
  public static toDomain(item: IHandoverQueuePersistence): IHandoverQueue {
    return {
      id: item.id,
      user: {
        id: item.user_id,
        fullName: item.user_name,
      },
      cashier: {
        id: item.cashier_id,
        fullName: item.cashier_name,
      },
      declarations: item.declarations.map((declaration) => ({
        id: declaration.declaration_id,
        trackCode: declaration.track_code.toString(),
        wardrobeNumber: declaration.wardrobe_number,
        box: declaration.container_id && declaration.container_name ? { id: declaration.container_id, name: declaration.container_name } : null,
      })),
      status: {
        id: item.state_id,
        name: item.state_name,
      },
      createdAt: item.created_at,
    };
  }
}
