import React, { FC, Fragment } from 'react';
import { Button, Col, Result, Row, Space, Tag, Checkbox } from 'antd';
import { NavLink } from 'react-router-dom';
import * as Icons from '@ant-design/icons';
import { ActionBar, GhostMenu, PageContent, SuspenseSpin } from '@core/ui';
import uniq from 'lodash/uniq';

import { HeadPortal } from '@modules/layout';
import { useAuth } from '@modules/auth';

import { HandoverItemCard, HandoverItemFooter, HandoverItemInfo } from '../components';
import { useHandoverQueues } from '../hooks';

export const HandoverQueuesPage: FC = () => {
  const { handoverType, data, isLoading, execute, error, remove, updateStatus, checkPrint, onChangeFilter } = useHandoverQueues();
  const { can } = useAuth();
  const header = (
    <HeadPortal>
      <ActionBar>
        <GhostMenu selectedKeys={[handoverType]} mode='horizontal'>
          <GhostMenu.Item key='queue'>
            Təhvil
            <NavLink to='/@next/warehouse/handover/queues' />
          </GhostMenu.Item>
          <GhostMenu.Item key='history'>
            Tarixçə
            <NavLink to='/@next/warehouse/handover/history' />
          </GhostMenu.Item>
        </GhostMenu>
      </ActionBar>
    </HeadPortal>
  );

  if (isLoading) {
    return <SuspenseSpin />;
  }

  if (error) {
    return (
      <Fragment>
        {header}
        <Result status='500' title={error.message} />
      </Fragment>
    );
  }

  if (!data) {
    return null;
  }

  if (!data.data.length) {
    return (
      <Fragment>
        {header}
        <Result status='404' title='Növbədə gözləyən müştəri yoxdur.' />
      </Fragment>
    );
  }

  return (
    <PageContent>
      {header}
      <Row gutter={[12, 12]} style={{ marginBottom: -6 }}>
        {data.data.map((item) => (
          <Col key={item.id} span={24} md={12} lg={8}>
            <HandoverItemCard
              title={`Növbə - #${item.id}`}
              extra={
                <div>
                  {item.status.id === 41 && (
                    <Tag icon={<Icons.LoadingOutlined />} color='warning'>
                      Gözləmədə
                    </Tag>
                  )}
                  {item.status.id === 42 && (
                    <Tag icon={<Icons.LoadingOutlined />} color='processing'>
                      İcra edilir
                    </Tag>
                  )}
                </div>
              }
            >
              <HandoverItemInfo size='small' column={1}>
                <HandoverItemInfo.Item label='Müştəri kodu'>{item.user.id}</HandoverItemInfo.Item>
                <HandoverItemInfo.Item label='Müştəri adı'>{item.user.fullName}</HandoverItemInfo.Item>
                <HandoverItemInfo.Item label='Bağlama sayı'>{item.declarations.length} ədəd</HandoverItemInfo.Item>
                <HandoverItemInfo.Item label='Yeşiklər'>
                  {uniq(item.declarations.filter((declaration) => declaration.box).map((declaration) => declaration.box?.name)).join(', ') || 'Qeyd olunmayıb'}
                </HandoverItemInfo.Item>
              </HandoverItemInfo>
              {(can('local_warehouse_execute') || can('local_warehouse_delete')) && (
                <HandoverItemFooter>
                  <Space size={4}>
                    <Checkbox style={{ marginRight: 10 }} checked={checkPrint} onChange={onChangeFilter}>
                      Çek çap edilsin
                    </Checkbox>

                    {can('local_warehouse_delete') && (
                      <Button onClick={() => remove.mutate({ queueId: item.id })} icon={<Icons.DeleteOutlined />} danger>
                        Ləğv et
                      </Button>
                    )}
                    {can('local_warehouse_execute') && (
                      <Fragment>
                        {item.status.id === 41 && (
                          <Button loading={updateStatus.isLoading} onClick={() => execute(item.id)} icon={<Icons.Loading3QuartersOutlined />} type='primary'>
                            İcra edirəm
                          </Button>
                        )}
                      </Fragment>
                    )}
                  </Space>
                </HandoverItemFooter>
              )}
            </HandoverItemCard>
          </Col>
        ))}
      </Row>
    </PageContent>
  );
};
