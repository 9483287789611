import React from 'react';
import { NextTable } from '@core/table';

import { useHandoverQueueTable } from '../hooks';
import { HandoverQueueTableContext } from '../context';

export const HandoverQueueTable = () => {
  const { columns } = useHandoverQueueTable();

  return <NextTable context={HandoverQueueTableContext} columns={columns} />;
};
