import { useMutation } from 'react-query';
import { message } from 'antd';
import { useService } from '@core/inversify-react';
import { IUpdateHandoverQueueStatusRepo, UpdateHandoverQueueStatusRepoType } from '../repos';

import { queryClient } from '../../../index';

export const useUpdateHandoverQueueStatus = () => {
  const updateHandoverQueuesStatusRepo = useService<IUpdateHandoverQueueStatusRepo>(UpdateHandoverQueueStatusRepoType);

  return useMutation<any, Error, { queueId: number; statusId: number }>(
    async ({ queueId, statusId }) => {
      const result = await updateHandoverQueuesStatusRepo.execute({ queueId, statusId });

      if (result.status !== 200) {
        throw new Error(result.response);
      }
    },
    {
      onError: async (error) => {
        message.error(error.message);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(['warehouse', 'handover-items']);
      },
    },
  );
};
