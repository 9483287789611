import * as React from 'react';
import { Column } from 'react-table';
import { Overflow, tableColumns } from '@core/table';
import uniqBy from 'lodash/uniqBy';

import { IHandoverQueue } from '../../interfaces';
import { Select } from 'antd';

export const useHandoverQueueTableColumns = () => {
  return React.useMemo<Column<IHandoverQueue>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: 'id',
        Header: 'Kod',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.user.id,
        id: 'user_id',
        Header: 'M. kodu',
      },
      {
        accessor: (row) => row.user.fullName,
        id: 'user_name',
        Header: 'M. adı',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.declarations,
        Header: 'Bağlamalar',
        filterable: false,
        sortable: false,
        Cell: ({ cell: { value } }) => `${value.length} bağlama`,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.declarations,
        Header: 'Yeşiklər',
        filterable: false,
        sortable: false,
        Cell: ({ row: { original } }) => {
          const boxes = uniqBy<{ id: number; name: string }>(
            original.declarations.map((declaration) => declaration.box).filter((box) => !!box),
            (box) => box.id,
          );

          return <Overflow>{boxes.map((box) => box.name).join(', ')}</Overflow>;
        },
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.status.id,
        id: 'state_id',
        Header: 'Status',
        Filter: ({ column: { setFilter, filterValue } }) => (
          <Select style={{ width: '100%' }} allowClear={true} onChange={setFilter} value={filterValue}>
            <Select.Option value='41'>Gözləmədə</Select.Option>
            <Select.Option value='42'>İcra edilir</Select.Option>
            <Select.Option value='43'>Təhvil verilib</Select.Option>
          </Select>
        ),
        Cell: ({ row: { original } }) => original.status.name,
      },
      {
        accessor: (row) => row.cashier.fullName,
        id: 'cashier_name',
        Header: 'İcraçı',
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: 'created_at',
        Header: 'Yaradılma tarixi',
      },
    ],
    [],
  );
};
