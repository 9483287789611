import { useParams } from 'react-router-dom';
import { Avatar, Button, Card, Col, Comment, Result, Row, Space } from 'antd';
import * as Icons from '@ant-design/icons';

import { PageContent, SuspenseSpin } from '@core/ui';

import { HandoverItemCard, HandoverItemInfo } from '../components';
import { useHandoverQueue } from '../hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { defaultFilterEnabled } from '../utils';

export const HandoverQueueDetails = () => {
  const hasPrintedRef = useRef(false);
  const { queueId } = useParams<{ queueId: string }>();
  const [checkPrint] = useState<boolean>(defaultFilterEnabled());
  const { queue, updateStatus, removeItem, removeMutation, remove, handover, printHandoverCheck } = useHandoverQueue(queueId);
  useEffect(() => {
    if (queue.data && checkPrint && !hasPrintedRef.current) {
      printHandoverCheck(queue.data);
      hasPrintedRef.current = true;
    }
  }, [checkPrint, queue.data, printHandoverCheck]);

  const renderPackage = useMemo(() => {
    if (!queue.data) return null;
    if (queue.data.packages && Object.values(queue.data.packages).every((elem) => elem === 0)) return <span> Paket üçün ödəniş edilməyib</span>;
    return (
      <Space direction='vertical'>
        <span>Kiçik: {queue.data?.packages?.smallPackage}</span>
        <span>Orta: {queue.data?.packages?.mediumPackage}</span>
        <span>Böyük: {queue.data?.packages?.bigPackage}</span>
      </Space>
    );
  }, [queue.data]);

  if (queue.isLoading) {
    return <SuspenseSpin />;
  }

  if (queue.error) {
    return <Result status='500' title={queue.error.message} />;
  }

  if (!queue.data) {
    return null;
  }

  return (
    <PageContent
      title={`Növbə #${queueId} - ${queue.data.declarations.length} bağlama`}
      extra={
        <Space size={8}>
          <Button loading={queue.isLoading} icon={<Icons.PrinterOutlined />} onClick={() => printHandoverCheck(queue.data)}>
            Çap et
          </Button>
          <Button danger={true} loading={removeMutation.isLoading} icon={<Icons.CheckCircleOutlined />} onClick={remove}>
            Ləğv et
          </Button>
          <Button type='primary' loading={updateStatus.isLoading} icon={<Icons.CheckCircleOutlined />} onClick={handover}>
            Təhvil verdim
          </Button>
        </Space>
      }
    >
      <Space style={{ width: '100%', justifyContent: 'space-between', paddingRight: 150 }}>
        <Comment
          avatar={<Avatar src='https://cdn.business2community.com/wp-content/uploads/2017/08/blank-profile-picture-973460_640.png' />}
          author={`#${queue.data.user.id}`}
          content={queue.data.user.fullName}
        />
        {renderPackage}
      </Space>
      <Row gutter={[12, 12]}>
        {queue.data.boxes.map((box) => (
          <Col key={box.id} xs={24}>
            <Card size='small' type='inner' title={`${box.name} - ${box.items.length} bağlama`}>
              <Row gutter={[12, 12]}>
                {box.items.map((item) => (
                  <Col key={item.id} xs={24} lg={6}>
                    <HandoverItemCard
                      extra={<Button onClick={() => removeItem.mutate({ queueId: parseInt(queueId), declarationId: item.id })} danger={true} icon={<Icons.DeleteOutlined />} />}
                      title={item.trackCode}
                    >
                      <HandoverItemInfo size='small' column={1}>
                        <HandoverItemInfo.Item label='Məhsul tipi'>{item.productType?.name || 'Qeyd olunmayıb'}</HandoverItemInfo.Item>
                        <HandoverItemInfo.Item label='Çəki'>{item.weight ? item.weight + ' kq' : 'Qeyd olunmayıb'}</HandoverItemInfo.Item>
                        <HandoverItemInfo.Item label='Mağaza'>{item.shop || 'Qeyd olunmayıb'}</HandoverItemInfo.Item>
                      </HandoverItemInfo>
                    </HandoverItemCard>
                  </Col>
                ))}
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </PageContent>
  );
};
