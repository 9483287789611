import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetHandoverQueuesRepoType, IGetHandoverQueuesRepo, IGetHandoverQueuesRepoData } from '../repos';

export const useHandoverQueueList = (filters = {}, options?: UseQueryOptions<IGetHandoverQueuesRepoData, Error, IGetHandoverQueuesRepoData, ['warehouse', 'handover-items', object]>) => {
  const getHandoverQueuesRepo = useService<IGetHandoverQueuesRepo>(GetHandoverQueuesRepoType);

  return useQuery<IGetHandoverQueuesRepoData, Error, IGetHandoverQueuesRepoData, ['warehouse', 'handover-items', object]>(
    ['warehouse', 'handover-items', filters],
    async ({ queryKey }) => {
      const [, , filters] = queryKey;

      const result = await getHandoverQueuesRepo.execute(filters);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
