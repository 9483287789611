import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IHandoverQueue } from '../interfaces';
import { HandoverQueueMapper } from '../mappers';

export type IGetHandoverQueuesRepoData = { data: IHandoverQueue[]; total: number; activeTasks: number[] };

export type IGetHandoverQueuesRepoResponse = IRepoResponse<200, IGetHandoverQueuesRepoData> | IRepoResponse<400 | 500, string>;

export type IGetHandoverQueuesRepo = IRepo<[any], IGetHandoverQueuesRepoResponse>;

export const GetHandoverQueuesRepoType = Symbol.for('GetHandoverQueuesRepo');

@bind(GetHandoverQueuesRepoType)
export class GetHandoverQueuesRepo implements IGetHandoverQueuesRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ state_id, ...query }: object & { state_id: string | string[] }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/handover/list', { ...query, state_id: typeof state_id === 'string' ? [state_id] : state_id }) });

      if (result.status === 200) {
        const data: IHandoverQueue[] = result.data.data.map((item) => HandoverQueueMapper.toDomain(item));
        const total: number = result.data.total;

        return {
          status: 200 as 200,
          response: { data, total, activeTasks: result.data.param.map((task) => task.id) },
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
