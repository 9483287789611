import * as React from 'react';
import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { GetHandoverQueuesRepoType, IGetHandoverQueuesRepo } from '../../repos';

export const useHandoverQueueTableContext = () => {
  const getHandoverItemsRepo = useService<IGetHandoverQueuesRepo>(GetHandoverQueuesRepoType);

  const handleFetch = React.useCallback(
    async (params: TableFetchParams) => {
      const result = await getHandoverItemsRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getHandoverItemsRepo],
  );

  return { handleFetch };
};
