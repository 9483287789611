import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useService } from '@core/inversify-react';

import { IDetailedHandoverQueue } from '../interfaces';
import { GetHandoverQueueRepoType, IGetHandoverQueueRepo } from '../repos';
import { usePrintHandoverCheck, useRemoveHandoverQueue, useRemoveHandoverQueueItem, useUpdateHandoverQueueStatus } from '../hooks';
import { useAuth } from '@modules/auth';

export const useHandoverQueue = (id: string) => {
  const history = useHistory();
  const { user } = useAuth();
  const getHandoverQueueRepo = useService<IGetHandoverQueueRepo>(GetHandoverQueueRepoType);

  const queue = useQuery<IDetailedHandoverQueue, Error, IDetailedHandoverQueue, string[]>(
    ['warehouse', 'handover-items', id],
    async ({ queryKey }) => {
      const [, , id] = queryKey;
      const result = await getHandoverQueueRepo.execute({ id });
      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {
      enabled: !!id,
    },
  );

  const updateStatus = useUpdateHandoverQueueStatus();
  const removeItem = useRemoveHandoverQueueItem();
  const removeMutation = useRemoveHandoverQueue();
  const printHandoverCheck = usePrintHandoverCheck(user.data);

  const remove = useCallback(() => {
    removeMutation.mutate(
      {
        queueId: parseInt(id),
      },
      {
        onSuccess: () => {
          history.push('/@next/warehouse/handover/queues');
        },
      },
    );
  }, [history, id, removeMutation]);

  const handover = useCallback(() => {
    updateStatus.mutate(
      { queueId: parseInt(id), statusId: 43 },
      {
        onSuccess: () => {
          history.push('/@next/warehouse/handover/queues');
        },
      },
    );
  }, [history, id, updateStatus]);

  return { queue, updateStatus, removeItem, removeMutation, remove, handover, printHandoverCheck };
};
