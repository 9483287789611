import styled from 'styled-components';
import { DragScroll } from '@core/ui';
import { Tag } from 'antd';

export const DraggableTags = styled(DragScroll)`
  display: flex;
  margin: 0 -12px;
  padding-left: 12px;
`;

export const DeclarationTag = styled(Tag)`
  padding-right: 24px;
  position: relative;

  .anticon-close {
    position: absolute;
    transform: translate(-50%, 0%);
    margin: 0;
    top: 0;
    right: -9px;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 4px;
  }
`;
